<template>
  <b-card title="Számlázási beállítások">
    <!-- form -->
    <b-form>
      <b-row>
        <b-col cols="2">
          <b-form-group
            label="Irányítószám"
            label-for="account-name"
          >
            <b-form-input
              v-model="invoiceData.posCode"
              type="number"
              placeholder="Irányítószám"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Város"
            label-for="account-name"
          >
            <b-form-input
              v-model="invoiceData.City"
              placeholder="Város"
            />
          </b-form-group>
        </b-col>
        <b-col cols="4">
          <b-form-group
            label="Utca"
            label-for="account-name"
          >
            <b-form-input
              v-model="invoiceData.Address"
              placeholder="Utca"
            />
          </b-form-group>
        </b-col>
        <b-col cols="3">
          <b-form-group
            label="Házszám"
            label-for="account-name"
          >
            <b-form-input
              v-model="invoiceData.HouseNumber"
              placeholder="Házszám"
            />
          </b-form-group>
        </b-col>
        <b-col cols="5">
          <b-form-group
            label="Adószám"
            label-for="account-name"
          >
            <b-form-input
              v-model="invoiceData.TaxNumber"
              placeholder="Adószám"
            />
          </b-form-group>
        </b-col>
        <b-col cols="12">
          <b-button
            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
            variant="primary"
            class="mt-1 mr-1"
          >
            Mentés
          </b-button>
          <b-button
            v-ripple.400="'rgba(186, 191, 199, 0.15)'"
            type="reset"
            class="mt-1"
            variant="outline-secondary"
            @click.prevent="resetForm"
          >
            Visszaállítás
          </b-button>
        </b-col>
      </b-row>
    </b-form>
  </b-card>
</template>

<script>
import {
  BButton,
  BForm,
  BFormGroup,
  BFormInput,
  BRow,
  BCol,
  BCard,
  BFormTextarea,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import flatPickr from 'vue-flatpickr-component'
import Ripple from 'vue-ripple-directive'

export default {
  components: {
    BButton,
    BForm,
    BFormGroup,
    BFormInput,
    BRow,
    BCol,
    BCard,
    BFormTextarea,
    vSelect,
    flatPickr,
  },
  directives: {
    Ripple,
  },
  props: {
    informationData: {
      type: Object,
      default: () => {},
    },
  },
  data() {
    return {
      invoiceData: {},
    }
  },
  methods: {
    resetForm() {
      // TODO
      // this.localOptions = JSON.parse(JSON.stringify(this.informationData));
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";
</style>
