<template>
  <b-tabs
    vertical
    content-class="col-12 col-md-9 mt-1 mt-md-0"
    pills
    nav-wrapper-class="col-md-3 col-12"
    nav-class="nav-left"
  >
    <b-tab active>
      <template #title>
        <feather-icon
          icon="UserIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Általános</span>
      </template>
      <general-info />
      <!-- TODO -->
      <!-- <general-info v-if="options.general" :general-data="options.general" /> -->
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="LockIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Jelszó változtatás</span>
      </template>
      <password-settings />
    </b-tab>
    <b-tab>
      <template #title>
        <feather-icon
          icon="InfoIcon"
          size="18"
          class="mr-50"
        />
        <span class="font-weight-bold">Számlázási beállítások</span>
      </template>
      <invoice-settings />
      <!-- TODO -->
      <!-- <invoice-settings v-if="options.info" :information-data="options.info" /> -->
    </b-tab>
  </b-tabs>
</template>

<script>
import { BTabs, BTab } from 'bootstrap-vue'
import GeneralInfo from './GeneralInfo.vue'
import PasswordSettings from './PasswordSettings.vue'
import InvoiceSettings from './InvoiceSettings.vue'

export default {
  components: {
    BTabs,
    BTab,
    GeneralInfo,
    PasswordSettings,
    InvoiceSettings,
  },
  data() {
    return {
      options: {},
    }
  },
  // TODO
  //   beforeCreate() {
  //     this.$http.get("/account-setting/data").then((res) => {
  //       this.options = res.data;
  //     });
  //   },
}
</script>
